const contact = function (FORM) {
  /*
   * Set up variables.
   */
  var name = $('input[name="name"]');
  var email = $('input[name="email"]');
  var message = $('textarea[name="message"]');
  var $result = $("#contact-result");
  var $error = $("#contact-error");
  var error = "";
  var focus = false;

  /*
   * Clear all input fields.
   */
  name.val("");
  email.val("");
  message.val("");

  /*
   * Check only for letters.
   */
  function validateAlpha(data) {
    var regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\ ]*$/;
    return regex.test(data);
  }

  /*
   * Email address validation.
   */

  function validateEmail(data) {
    var regex =
      /^[0-9a-z]+([\.\-_][0-9a-z]+)*\@[0-9a-z]+([\.\-][0-9a-z]+)*\.[a-z]{2,4}$/;
    return regex.test(data);
  }

  /*
   * Trigger error function.
   */

  function trigError(string) {
    if (error == "") error = string;
  }

  function setFocus(name) {
    if (focus == false) {
      $("[name=" + name + "]", FORM).focus();
      focus = true;
    }
  }

  /*
   * Form submit function.
   */

  FORM.submit(function (e) {
    e.preventDefault();

    $error.hide();
    error = "";
    focus = false;

    /*
     * Name Validation.
     */

    if (!name.val()) {
      trigError(__TRANSLATION["contact-error-name"]);
      setFocus("name");
    }

    /*
     * E-Mail Validation.
     */

    if (!email.val() || validateEmail(email.val()) === false) {
      trigError(__TRANSLATION["contact-error-email"]);
      setFocus("email");
    }
    // else if(valEmail(email.val())===false){
    // trigError('E-mail?');
    // };

    /*
     * Message validation.
     */

    if (!message.val()) {
      trigError(__TRANSLATION["contact-error-message"]);
      setFocus("message");
    }

    /*
     * Check for errors. If not present - send a mail.
     */
    if (error) {
      $error.addClass("error").html(error).stop().slideDown();
    } else {
      FORM.fadeOut(250, function () {
        $.post(
          "/send",
          FORM.serialize(),
          function (response) {
            if (typeof response.status !== undefined) {
              if (response.status === 1) $result.addClass("success");
              else if (response.status === -1) $result.addClass("error");
            }
            if (typeof response.message !== undefined)
              $result.addClass("error").html(response.message).fadeIn(500);
          },
          "json"
        ).fail(function () {
          $result
            .addClass("error")
            .html(__TRANSLATION["contact-error-backend"])
            .fadeIn(500);
        });
      });
    }
  });
};

export default contact;
