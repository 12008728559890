require("./js/main");
import gallery from "./js/gallery";
import contact from "./js/contact";

(function () {
  /* Gallery callback. */
  SITE.onShow(function () {
    const $container = $("#artwork"); // Container for thumbnails.
    const $thumbnails = $(".thumbnail", $container); // Gallery items (thumbnails).
    if ($container.length && $thumbnails.length) {
      gallery($container, "#artwork a", $thumbnails);
    }
  });

  /* Contact form callback. */
  SITE.onShow(function () {
    const form = $("#contactForm");
    if (form.length) {
      contact(form);
    }
  });

  /* Page load callback. */
  window.addEventListener("load", function (event) {
    SITE.executeOnShow();
  });
})();

// require("./js/animated-bg");
