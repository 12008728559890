(function () {

  const menuData = require('./menu');

  if (typeof __TRANSLATION === "undefined") {
    __TRANSLATION = {};
  }

  if (typeof SITE !== "undefined") return;

  SITE = {}; // Global variable that holds all important functions.

  SITE.init = function () {
    var self = this;

    self.domain = (function () {
      return "http://" + window.location.hostname;
    })();

    $.ajaxSetup({ cache: false }); // Disable AJAX cache.

    // Prepare menu.
    self.buildMenu($("#menu"), menuData);

    self.initScrollbar(self.$content); // Add scrollbar to content container.
  };

  SITE.contentDimensions = {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };

  SITE.updateContentDimensions = function () {
    var self = this;
    var $element = $("#content-wrapper");
    self.contentDimensions = {
      width: $element.css("width"),
      height: $element.css("height"),
      top: $element.offset().top,
      left: $element.offset().left,
    };
  };

  SITE.traceSpeed = 250;
  SITE.traceDelay = 100;

  function handleActiveItem(items) {
    const currentURL = window.location.pathname;
    let isSet = false;
    items.forEach(function (item) {
      item.active = false;
      if (item.url === currentURL) {
        item.active = true;
        isSet = true;
      }
    });
    if (isSet === false) {
      items[0].active = true;
    }
  }

  /* This function builds menu */
  SITE.buildMenu = function ($container, items) {
    handleActiveItem(items);

    if (!$container) {
      return;
    }

    const self = this;
    const delay = 50;
    const speed = 1000;
    const $toggleMenu = $(
      '<i id="menu-toggle-button" class="fa fa-bars"></i>'
    ).appendTo($container); // Add button for rolling the menu down/up;
    const $menuWrapper = $("#menu-wrapper");
    let timeout = delay;

    $toggleMenu.click(function () {
      $menuWrapper.toggleClass("menu-shown");
    });

    $.each(items, function (i, item) {
      const $link = $('<a href="' + item.url + '"/>')
        .append('<i class="menu-item-icon ' + item.icon + '"></i>')
        .append('<div class="menu-item-title">' + item.title + "</div>")
        .click(function (e) {
          e.preventDefault();

          /* Clicked menu items links to external page */
          if (item.external === true) {
            window.open(item.url);
            return;
          }

          if ($menuWrapper.hasClass("menu-shown")) {
            $menuWrapper.removeClass("menu-shown");
          }

          if ($(this).hasClass("menu-item-active")) return;

          self.lock();
          self.updateContentDimensions();
          self.closePage(function () {
            $link.addClass("menu-item-active");
            self.traceElements($link, self.contentDimensions, function (e) {
              self.loadPage(item.url, function () {
                e.fadeOut(self.traceSpeed, function () {
                  e.remove();
                  self.unlock();
                });
              });
            });
          });
        });

      if (item.active === true) {
        $link.addClass("menu-item-active");
      }

      const $menuItem = $(
        '<li class="menu-item clearfix" style="display: none;"/>'
      ).append($link);

      setTimeout(function () {
        $menuItem.appendTo($container).fadeTo(speed, 1);
        var origPadding = $link.css("paddingTop");
        var targetPadding = 30;

        $link.hover(
          function () {
            $(this).stop();
          },
          function () {
            $(this).stop();
          }
        );
      }, timeout);

      timeout += delay;
    });

    setTimeout(function () {
      self.initScrollbar($("#menu"));
    }, (items.length + 1) * delay);
  };

  SITE.$content = $("#content");
  SITE.$contentWrapper = $("#content-wrapper");

  SITE.setContent = function (content) {
    var self = this;
    self.$content.html(content);
    return self.$content;
  };

  SITE.loadPage = function (url, callback) {
    window.history.pushState("", "", url);

    var self = this;
    $.get(url, function (content) {
      if (!content) {
        return false;
      }
      if (typeof callback === "function") callback();
      self.setContent(content).fadeIn(self.traceSpeed, function () {
        self.$content.scrollTop(0);
        self.executeOnShow();
      });
    }).fail(function () {
      if (typeof callback === "function") callback();
      self.setContent("404").fadeIn(self.traceSpeed, function () {
        self.$content.scrollTop(0);
      });
    });
  };

  SITE.onShowFunctions = [];
  SITE.onShow = function (fn = null) {
    if (fn && typeof fn === "function") {
      this.onShowFunctions.push(fn);
    }
  };
  SITE.executeOnShow = function () {
    var self = this;
    $.each(self.onShowFunctions, function (i, f) {
      if (typeof f === "function") f();
    });
  };

  /* Close current page */
  SITE.closePage = function (callback) {
    var self = this;
    var $currentLink = $(".menu-item-active");
    var $contentWrapper = self.$contentWrapper;

    var dimensions = {
      top: $currentLink.offset().top,
      left: $currentLink.offset().left,
      width: $currentLink.css("width"),
      height: $currentLink.css("height"),
    };

    self.lock(); // lock the screen first...

    // ...then hide the content
    self.$content.fadeOut(self.traceSpeed, function () {
      self.setContent("");
      self.traceElements($contentWrapper, dimensions, function (e) {
        $currentLink.removeClass("menu-item-active");
        e.fadeOut(self.traceSpeed, function () {
          e.remove();
          if (typeof callback === "function") callback();
        });
      });
    });
  };

  /* Show animated 'trace' from one element to another */
  SITE.traceElements = function ($element1, dimensions, callback) {
    var self = this;
    var $trace = $('<div class="trace"/>');
    var $body = $("body");

    var element1Position = $element1.offset();
    var padding = 6;

    $trace.appendTo($body).css({
      width: $element1.css("width"),
      height: $element1.css("height"),
      top: element1Position.top,
      left: element1Position.left,
    });

    $trace.animate(
      {
        top: dimensions.top,
        left: dimensions.left,
        width: parseInt(dimensions.width) - padding,
        height: parseInt(dimensions.height) - padding,
      },
      {
        duration: self.traceSpeed,
        easing: "linear",
        complete: function () {
          if (callback && typeof callback === "function") callback($trace);
        },
      }
    );
  };

  /* Show overlay that prevents user interaction */
  SITE.lock = function () {
    $("body").append('<div class="lock-overlay"/>');
  };

  /* Remove overlay */
  SITE.unlock = function () {
    $(".lock-overlay").remove();
  };

  /* Initialize scrollbar for element. */
  SITE.initScrollbar = function (element) {
    $(document).ready(function () {
      element.scrollbar({
        autoUpdate: true,
      });
    });
  };

  SITE.scaleMenu = function () {
    var height = $(document).height();
    var itemsCount = $(".menu-item").length;
    var itemHeight = height / itemsCount;
    var factor = itemHeight / 130;
    $(".menu-item-title").css(
      "fontSize",
      parseFloat($(".menu-item-title").css("fontSize")) * factor
    );
    $(".menu-item-icon").css(
      "fontSize",
      parseFloat($(".menu-item-icon").css("fontSize")) * factor
    );
  };

  SITE.init();
})();
