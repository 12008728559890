const menu = [
    {
        title: "About Me",
        icon: "fa fa-home",
        url: "/about-me",
        active: true,
    },
    {
        title: "Experience",
        icon: "fa fa-code",
        url: "/experience",
    },
    {
        title: "GitHub",
        icon: "fab fa-github-alt",
        url: "http://www.github.com/ppokojowczyk/",
        external: true,
    },
    {
        title: "Artwork",
        icon: "fa fa-paint-brush",
        url: "/artwork",
    },
    {
        title: "Contact",
        icon: "fa fa-at",
        url: "/contact",
    },
    {
        title: "Facebook",
        icon: "fab fa-facebook",
        url: "http://www.facebook.com/pokojowczyk/",
        external: true,
    },
    {
        title: "Twitter",
        icon: "fab fa-twitter",
        url: "http://www.twitter.com/pokojowczyk/",
        external: true,
    },
    {
        title: "Skype",
        icon: "fab fa-skype",
        url: "skype:piotr.pokojowczyk?call",
        external: true,
    },
    // {
    //     title: "WhatsApp",
    //     icon: "fab fa-whatsapp",
    //     url: "https://wa.me/",
    //     external: true,
    // },
    // {
    //     title: "Call Me",
    //     icon: "fas fa-phone",
    //     url: "tel:",
    //     external: true,
    // },
];

module.exports = menu;