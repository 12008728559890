const gallery = function ($container, itemSelector, $thumbnails) {
  /**
   * Initialize "hover/focus" effects for gallery items.
   * @param {Object} $container
   * @param {Object} $thumbnails
   * @param {Number} defaultItemOpacity
   * @param {Number} unfocusedItemOpacity
   */
  function initializeHoverEffects(
    $container,
    $thumbnails,
    defaultItemOpacity,
    unfocusedItemOpacity
  ) {
    $thumbnails.hover(
      function () {
        $("img", $container)
          .not(this)
          .stop()
          .fadeTo("fast", unfocusedItemOpacity);
        $("img", this).stop().fadeTo("fast", 1);
      },
      function () {
        $("img", $container)
          .not(this)
          .stop()
          .fadeTo("slow", defaultItemOpacity);
        $("img", this).stop().fadeTo("slow", defaultItemOpacity);
      }
    );
  }

  /**
   * Init tiling plugin.
   * @param {Object} $container
   * @param {String} itemSelector
   * @param {Number} containerFadeIn
   */
  function initializeTilingPlugin($container, itemSelector, containerFadeIn) {
    $container.fadeIn(containerFadeIn);
    $container.isotope({
      itemSelector: itemSelector,
      masonry: {
        columnWidth: ".thumbnail",
      },
      percentPosition: true,
    });
  }

  setTimeout(function () {
    initializeTilingPlugin($container, itemSelector, 100);
    initializeHoverEffects($container, $thumbnails, 1, 0.5);
  }, 250);
};

export default gallery;
